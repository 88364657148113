<template>
  <div class="view-account container-fluid pt-5">
    <h4 class="font-weight-bold">Minha conta</h4>
    <hr />

    <form class="mb-5" @submit.prevent="submit">
      <form-group
        id="email"
        v-model="form.email"
        :errors="errors.email"
        type="email"
        placeholder="Seu e-mail"
        readonly
      />

      <form-group
        id="name"
        v-model="form.name"
        :errors="errors.name"
        type="text"
        placeholder="Seu nome"
      />

      <form-group
        id="phone"
        v-model="form.phone"
        :errors="errors.phone"
        type="text"
        placeholder="Número de celular"
      />

      <button type="submit" class="btn btn-primary px-4" :disabled="loading">
        <loading :show="loading">Salvar</loading>
      </button>
    </form>

    <change-password />
  </div>
</template>

<script>
import { formFields } from "@/functions/helpers.js";
import ChangePassword from "@/components/account/ChangePassword";

export default {
  components: { ChangePassword },
  data() {
    return {
      loading: false,
      ...formFields(["name", "phone", "email"], {
        ...this.$user.user
      })
    };
  },
  methods: {
    submit() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.$store
        .dispatch("user/update", this.form)
        .then(() => {
          this.$message.success("Salvo com sucesso");
        })
        .catch(error => {
          this.errors = error.response.data;
          if (this.errors.non_field_errors) {
            this.$message.error(this.errors.non_field_errors[0]);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss">
.view-account {
  h4 {
    margin-bottom: 8px;
  }

  hr {
    border-color: white;
    margin: 0 0 24px;
  }

  form {
    max-width: 500px;
  }
}
</style>

<template>
  <div class="account-change-password">
    <h4 class="font-weight-bold">Alterar senha</h4>
    <hr />
    <form @submit.prevent="submit">
      <form-group
        id="password_old"
        v-model="form.password_old"
        :errors="errors.password_old"
        type="password"
        placeholder="Senha atual"
      />

      <form-group
        id="password"
        v-model="form.password"
        :errors="errors.password"
        type="password"
        placeholder="Nova senha"
      />

      <button type="submit" class="btn btn-primary px-4" :disabled="loading">
        <loading :show="loading">Salvar</loading>
      </button>
    </form>
  </div>
</template>

<script>
import { formFields } from "@/functions/helpers.js";

export default {
  data() {
    return {
      loading: false,
      ...formFields(["password_old", "password"])
    };
  },
  methods: {
    submit() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.$store
        .dispatch("user/updatePassword", this.form)
        .then(() => {
          this.$message.success("Senha alterada com sucesso");
        })
        .catch(error => {
          this.errors = error.response.data;
          if (this.errors.non_field_errors) {
            this.$message.error(this.errors.non_field_errors[0]);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
